import 'intersection-observer';

let options = {
    threshold: 0.0,
    rootMargin: "0px 0px -50% 0px"
}

let setInview = (entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.dataset.inview = true;
            observer.unobserve(entry.target);
        }
    });
}

let observer = new IntersectionObserver(setInview, options);
let targets = document.querySelectorAll('[data-inview="false"]');

targets.forEach(target => {
    observer.observe(target);
});