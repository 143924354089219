import Vue from 'vue';
import Bouncer from 'formbouncerjs';

if (document.querySelector('#contact-form-vue')) {
    const API_DOMAIN = window.wpApiSettings && window.wpApiSettings.api_domain ? window.wpApiSettings.api_domain : 'https://monarchprivate.com';
    const NONCE = window.wpApiSettings && window.wpApiSettings.nonce ? window.wpApiSettings.nonce : null;

    var contact_form = new Vue({
        el: '#contact-form-vue',
        data: {
            fields: {
                full_name: '',
                company_name: '',
                email: '',
                phone: '',
                message: '',
                name: '' //honeypot
            },
            is_loading: false,
            validation: null,
            form: null
        },
        mounted: function() {
            this.form = document.querySelector('#contact-form-vue');
            this.validation = new Bouncer();
        },
        methods: {
            onSubmitForm() {
                this.submitForm();
            },
            submitForm() {
                if(this.fields.name && this.fields.name.length) {
                    window.location = '/thank-you';
                    return;
                }

                if (!this.validation.validateAll(this.form).length) {
                    this.is_loading = true;
    
                    return fetch(`${API_DOMAIN}/wp-json/contact-us/submission`, {
                        method: 'POST',
                        body: JSON.stringify(this.getSubmissionData()),
                        headers: {
                            'X-WP-NONCE': NONCE
                        }
                    })
                    .then(response => response.json())
                    .then((response) => {
                        this.confirmation_message = response.confirmation_message ? response.confirmation_message.replace(/(<([^>]+)>)/ig,"") : false;
    
                        if(response.confirmation_type === 'redirect' && response.confirmation_redirect) {
                            window.location = response.confirmation_redirect;
                        }
    
                        this.is_loading = false;
                    });
                }
            },

            getSubmissionData() {
                let data = Object.assign({}, {nonce: NONCE}, {required_field: 'true'});

                Object.keys(this.fields).forEach((field) => {
                    data[field] = this.fields[field];
                });

                return data;
            },
        }
    })
}