import Glide from '@glidejs/glide';

let image_sliders = document.querySelectorAll('[data-image-slider]');

image_sliders.forEach(image_slider => {
    let image_slider_glide = new Glide(image_slider, {
        type: 'carousel',
        gap: 40,
        perView: 4,
        perTouch: 1,
        autoplay: 3000,
        animationDuration: 800,
        animationTimingFunc: 'ease-in-out',
        breakpoints: {
            1024: {
                perView: 3
            },
            800: {
                perView: 2
            },
            420: {
                perView: 1
            }
        }
    }).mount();
});