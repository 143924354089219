function initMap(el) {
    var markers = el.querySelectorAll('.marker');

    var mapArgs = {
        zoom        : parseInt(el.dataset.zoom) || 16,
        mapTypeId   : google.maps.MapTypeId.ROADMAP,
        markers     : []
    };

    var map = new google.maps.Map( el, mapArgs );

    markers.forEach(marker => {
        initMarker(marker, map);
    });

    centerMap(map);

    return map;
}

function initMarker(marker, map) {
    var lat = marker.dataset.lat;
    var lng = marker.dataset.lng;

    var latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
    };

    var map_marker = new google.maps.Marker({
        position : latLng,
        map: map
    });

    map.markers.push(map_marker);

    if (marker.innerHTML) {
        var infowindow = new google.maps.InfoWindow({
            content: marker.innerHTML
        });

        google.maps.event.addListener(map_marker, 'click', () => {
            infowindow.open( map, map_marker );
        });
    }
}

function centerMap(map) {
    var bounds = new google.maps.LatLngBounds();

    map.markers.forEach(marker => {
        bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng()
        });
    });

    if( map.markers.length == 1 ){
        map.setCenter(bounds.getCenter());
    } else{
        map.fitBounds(bounds);
    }
}

var acf_maps = document.querySelectorAll('.acf-map');

acf_maps.forEach(acf_map => {
    var map = initMap(acf_map);
});