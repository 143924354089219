import Glide from '@glidejs/glide';
import Modal from '../lib/Modal';
import Player from '@vimeo/player';

let vimeo_players_containers = document.querySelectorAll('[data-vimeo-id]');
let vimeo_players = [];
vimeo_players_containers.forEach((el) => {
    let player = new Player(el, {
        id: el.dataset.vimeoId,
        portrait: false,
        byline: false,
        title: false
    });
    vimeo_players.push(player);
});

const stopVideos = (players) => {
    players.forEach((player) => {
        player.pause();
    });
}

let sliders = document.querySelectorAll('[data-glide-slider]');
sliders.forEach((slider) => {
    let modal_el = slider.querySelector('[data-slider-modal]');
    let modal_body = slider.querySelector('[data-slider-modal-body]');
    let glide;
    let glide_el = slider.querySelector('.glide');

    let modal;
    let modal_slider_el
    let slides = slider.querySelectorAll('.project-slide-image');
    let lightbox_toggle = slider.querySelector('[data-lightbox-toggle]');

    modal_slider_el = glide_el ? glide_el.cloneNode(true) : slider.querySelector('.no-glide').cloneNode(true);
    modal_slider_el.classList.remove('glide-src');

    
    slides.forEach((slide) => {
        slide.addEventListener('click', (e) => {
            stopVideos(vimeo_players);
            modal.showModal();
        });
    });

    if(lightbox_toggle) {
        lightbox_toggle.addEventListener('click', (e) => {
            stopVideos(vimeo_players);
            modal.showModal();
        });
    }
    
    if (slider.querySelectorAll('.glide-src').length) {
        glide = new Glide('.glide-src', {
            'type': 'carousel',
            'peek': 125,
            'gap': 0,
            'arrows': true,
            breakpoints: {
                800: {
                    'peek': 0
                }
            }
        }).mount();
    
        glide.on(['mount.before', 'run'], function () {
            stopVideos(vimeo_players);
        })
    }
    
    if (slider.querySelectorAll('.glide-src-alt').length) {
        glide = new Glide('.glide-src-alt', {
            'type': 'carousel',
            'gap': 0,
            'arrows': true
        }).mount();
    }

    if(modal_el && modal_body) {
        let modal_slider;
        let modal_options = {};
        modal_body.innerHTML = '';

        modal_body.appendChild(modal_slider_el);

        if(glide_el) {            
            modal_options = {
                onOpen: function () {
                    modal_slider = new Glide(modal_body.querySelector('.glide'), {
                        'type': 'carousel',
                        'gap': 0,
                        'startAt': glide.index, 
                        'arrows': true
                    }).mount();
    
                    modal_slider.on(['mount.before', 'run'], function () {
                        stopVideos(vimeo_players);
                    })
                },
                onClose: function () {
                    modal_slider.destroy();
                    modal_slider = null;
                    stopVideos(vimeo_players);
                }
            }
        }

        let vimeo_players_containers = modal_body.querySelectorAll('[data-vimeo-id]');
        let vimeo_players = [];
        vimeo_players_containers.forEach((el) => {
            let player = new Player(el, {
                id: el.dataset.vimeoId,
                byline: false,
                portrait: false,
                title: false
            });
            vimeo_players.push(player);
        });

        modal = new Modal(modal_el, modal_options);
    }
});



