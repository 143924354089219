<template>
    <svg xmlns="http://www.w3.org/2000/svg" 
        class="state-map" 
        :viewBox="map_data.view_box" 
        @click="onClick"
        @mouseover="onMouseEnter"
    >
        <g id="United_States">
            <path v-for="(state_path, index) in map_data.state_paths" 
                :key="`path-${index}`"
                :data-state="state_path.data_state" 
                :d="state_path.d" 
                :transform="state_path.transform" 
                :class="state_path.class"
                @mouseleave="onMouseLeave"
            />

            <polygon v-for="(state_polygon, index) in map_data.state_polygons" 
                :key="`polygon-${index}`"
                :data-state="state_polygon.data_state" 
                :points="state_polygon.points" 
                :class="state_polygon.class"
                @mouseleave="onMouseLeave"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'StateMapSvg',
        props: {
            map_data: {type: Object}
        },
        methods: {
            onClick(e) {
                this.sendEvent(e.target, 'click');
            },
            onMouseEnter(e) {
                this.sendEvent(e.target, 'mouseenter');
            },
            onMouseLeave(e) {
                this.sendEvent(e.target, 'mouseleave');
            },
            sendEvent(target, type = '') {
                if(target.classList.contains('has-data') && target.dataset.state) {
                    this.$emit('mapClicked', {
                        event_type: type,
                        state_code: target.dataset.state
                    });
                }
            }
        }
    }
</script>
