var medallions = document.querySelectorAll('.medallion');

medallions.forEach(function(medallion) {
    medallion.addEventListener('mouseenter', onMedallionHoverIn);
    medallion.addEventListener('mouseleave', onMedallionHoverOut);
});

function onMedallionHoverIn(e) {
    if(window.innerWidth < 1100) {
        return;
    }

    medallions.forEach(function(medallion) {
        medallion.classList.add('out');
    });

    document.querySelector('.map').classList.add('out');
    e.target.classList.remove('out');
}

function onMedallionHoverOut(e) {
    if(window.innerWidth < 1100) {
        return;
    }

    medallions.forEach(function(medallion) {
        medallion.classList.remove('out');
    });

    document.querySelector('.map').classList.remove('out');
}