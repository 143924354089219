class Accordion {
    constructor(accordion) {
        this.accordion = accordion;
        this.accordion_toggles = accordion.querySelectorAll('[data-accordion-toggle]');

        this.accordion_toggles.forEach(toggle => {
            toggle.addEventListener('click', e => {
                e.preventDefault();
                this.toggleItem(toggle, 'toggle');
            });

            if (toggle.id) {
                this.setupOtherLinks(toggle);
            }
        });
    }

    toggleItem(toggle, state) {
        if (state == 'open') {
            toggle.closest('[data-accordion-item]').classList.add('active');
        } else {
            toggle.closest('[data-accordion-item]').classList.toggle('active');
        }
    }

    setupOtherLinks(toggle) {
        let other_links = document.querySelectorAll('[href*="#' + toggle.id + '"]');

        other_links.forEach(link => {
            link.addEventListener('click', e => {
                this.toggleItem(toggle, 'open');
            });
        })
    }
}

export default Accordion;