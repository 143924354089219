class Slider {
    constructor(slider) {
        this.state = {
            current: 0
        }

        this.slider = slider;
        this.slides_container = slider.querySelector('[data-slider-slides]');
        this.slides = slider.querySelectorAll('[data-slider-slide]');
        this.pagination = slider.querySelectorAll('[data-slider-pagination]');
        this.slider_prev = slider.querySelectorAll('[data-slider-prev]');
        this.slider_next = slider.querySelectorAll('[data-slider-next]');

        this.options = {
            autoplay: false,
            autoplay_interval: parseInt(this.slider.dataset.sliderAutoplay) || 0
        }

        this.pagination.forEach(pagination => {
            pagination.addEventListener('click', e => {
                e.preventDefault();
                this.switchSlide(pagination.dataset.sliderPagination);

                if (this.options.autoplay) {
                    clearInterval(this.options.autoplay);
                }
            });
        });

        this.slider_prev.forEach(prev => {
            prev.addEventListener('click', e => {
                e.preventDefault();
                this.switchSlide(this.state.current - 1);

                if (this.options.autoplay) {
                    clearInterval(this.options.autoplay);
                }
            });
        });

        this.slider_next.forEach(next => {
            next.addEventListener('click', e => {
                e.preventDefault();
                this.switchSlide(this.state.current + 1);

                if (this.options.autoplay) {
                    clearInterval(this.options.autoplay);
                }
            });
        });

        this.setHeight();

        this.slides[this.state.current].classList.add('active');

        window.addEventListener('load', () => this.setHeight());
        window.addEventListener('resize', () => this.setHeight());

        if (this.options.autoplay_interval) {
            this.startAutoplay();
        }
    }

    switchSlide(index) {
        if (index < 0) {
            index = this.slides.length - 1;
        }

        if (index > this.slides.length - 1) {
            index = 0;
        }

        this.state.current = index;

        this.slides.forEach(slide => {
            slide.classList.remove('active');
        });

        this.slides[this.state.current].classList.add('active');

        let page_hero_slide = this.slides[this.state.current].querySelector('.page-hero-slide-background');

        if (page_hero_slide) {
            page_hero_slide.classList.add('page-hero-slide-background-' + (parseInt(index) + 1));
        }

        this.pagination.forEach(pagination => {
            pagination.classList.remove('active');
        });
        
        this.pagination[this.state.current].classList.add('active');
    }

    setHeight() {
        var height = 0;
        this.slides_container.removeAttribute('style');

        this.slides.forEach(slide => {
            if (slide.offsetHeight > height) {
                height = slide.offsetHeight;
            }
        });

        this.slides_container.style.height = height + 'px';
    }

    startAutoplay() {
        if (this.options.autoplay) {
            clearInterval(this.options.autoplay);
        }

        this.options.autoplay = setInterval(() => {
            this.switchSlide(this.state.current + 1);
        }, this.options.autoplay_interval)
    }
}

export default Slider;