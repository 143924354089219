<template>
    <div id="state-map-top" class="state-map-app" @mousemove="onMouseMove" ref="app-container">
        <div v-if="loading" class="loader"></div>

        <transition name="fade">
            <div v-if="!loading">
                <div class="text-center">
                    <strong><span class="mobile-hidden">Click or </span>Select a state below for State Tax Credit Information</strong>
                </div>

                <div class="flex flex-justify-center has-gutters-top-half">
                    <div class="flex-col flex-col-4">
                        <select @change="onStateSelect" class="input input-black">
                            <option>Select State</option>
                            <option v-for="(state, index) in states" 
                                :key="index" 
                                :value="state.key"
                                :selected="(current_state && current_state.key === state.key) ? 'selected' : false"
                            >
                                {{state.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="flex flex-justify-center has-gutters-top">
                    <div class="flex-col flex-col-10">
                        <div class="state-map-key has-gutters-bottom-half">
                            <span class="key-entry key-entry-film">Transferable Film Credits</span>
                            <span class="key-entry key-entry-credits">Other State Tax Credits Monarch Works With</span>
                        </div>
                        
                        <StateMapSvg :map_data="map_data" @mapClicked="handleMapEvent"></StateMapSvg>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="fade">
            <div id="state-map-selected" v-if="current_state" class="wysiwyg-content state-map-selected">
                <div class="has-gutters-top">
                    <h5>Selected State: {{current_state.name}}</h5>

                    <div v-if="state_loading" class="loader"></div>

                    <div v-html="current_state.content"></div>

                    <div class="has-gutters-top">
                        <a :href="current_state.permalink" class="button button-black">View State</a>
                    </div>
                </div>

                <hr class="has-gutters-top" />
            </div>
        </transition>

        <div data-popup="state-map-popup" 
            class="state-map-popup" 
            :class="(popup_conent || popup_state) ? 'show' : ''"
            ref="popup"
        >
            <div><strong>{{popup_state}}</strong></div>

            <div v-if="popup_credits && popup_credits.length" class="wysiwyg-content wysiwyg-content-small">
                <ul>
                    <li v-for="(credit, index) in popup_credits" :key="index">{{credit.credit}}</li>
                </ul>
            </div>
            
            <div v-html="popup_content" 
                class="wysiwyg-content wysiwyg-content-small"
                :class="popup_credits && popup_credits.length ? 'has-gutters-top-half' : ''"
            >
                {{popup_content}}
            </div>
        </div>
    </div>
</template>

<script>
    import StateMapSvg from './StateMapSvg.vue'

    const state_map_svg = document.getElementById('state-map-app') ? document.getElementById('state-map-app').dataset.mapSvg : '/app/themes/monarchprivate/dist/img/state-map.svg';

    export default {
        components: {StateMapSvg},
        data: () => {
            return {
                loading: true,
                state_loading: false,
                states: [],
                current_state: null,
                popup_content: '',
                credits: [],
                popup_state: '',
                map_data: {
                    view_box: '0 0 100 100',
                    state_paths: [],
                    state_polygons: []
                }
            }
        },
        created: function() {
            if(window.location.hash === '#state-map-selected') {
                window.location.hash = '';
            }

            this.fetchStates().then(() => {
                this.fetchMapSVG(state_map_svg)
                .then(this.parseMapSVG)
                .then(() => {
                    this.loading = false;
                });
            });
        },
        methods: {
            fetchMapSVG: function(file_path) {
                return fetch(file_path).then(results => results.text());
            },
            parseMapSVG: function(svg_string) {
                if(!svg_string) {
                    return false;
                }

                let map_data = {
                    state_paths: [],
                    state_polygons: []
                };
                let parser = new DOMParser();
                let doc = parser.parseFromString(svg_string, "image/svg+xml");
                let state_paths = doc.rootElement.querySelectorAll('path[data-state]');
                let state_polygons = doc.rootElement.querySelectorAll('polygon[data-state]');
                map_data.view_box = doc.rootElement.getAttribute('viewBox');

                state_paths.forEach((state_path) => {
                    let state = this.getStateByCode(state_path.getAttribute('data-state'));
                    let new_state = {
                        data_state: state_path.getAttribute('data-state'),
                        d: state_path.getAttribute('d'),
                        transform: state_path.getAttribute('transform'),
                        class: state ? 'has-data' : ''
                    };

                    if(state && state.credits) {
                        let credit_class = '';

                        state.credits.forEach((credit) => {
                            new_state.class += ` ${credit.credit.trim().replace(/\s+/g, '-').toLowerCase()}`;
                        });
                    }

                    map_data.state_paths.push(new_state);
                });

                state_polygons.forEach((state_polygon) => {
                    map_data.state_polygons.push({
                        data_state: state_polygon.getAttribute('data-state'),
                        points: state_polygon.getAttribute('points'),
                        class: this.getStateByCode(state_polygon.getAttribute('data-state')) ? 'has-data' : ''
                    });
                });

                this.map_data = map_data;
            },
            fetchStates: function() {
                return fetch(`${wpApiSettings.api_domain}/wp-json/state-tax-credits/view/`)
                    .then(response => response.json())
                    .then(data => {
                        this.states = data;
                    });
            },
            fetchState: function(state_id) {
                return fetch(`${wpApiSettings.api_domain}/wp-json/state-tax-credits/view/?id=${state_id}`)
                    .then(response => response.json())
            },
            selectState: function(id) {
                if(window.location.hash === '#state-map-selected') {
                    window.location.hash = '';
                }

                this.current_state = this.getStateByCode(id);

                if(this.current_state) {
                    if(!this.current_state.content) {
                        this.state_loading = true;
                        this.fetchState(this.current_state.id).then((states) => {
                            if(states.length) {
                                this.current_state = Object.assign(this.current_state, states[0]);
                            }
                            this.state_loading = false;
                        });
                    }

                    window.setTimeout(() => {
                        window.location.hash="#state-map-selected";
                    }, 0);
                }
            },
            getStateByCode: function(code) {
                return this.states.find(element => element.key === code);
            },
            handleMapEvent: function(e) {
                let state = this.getStateByCode(e.state_code);
                
                switch(e.event_type) {
                    case 'click':
                        this.selectState(e.state_code);
                        break;
                    case 'mouseenter': 
                        this.popup_content = state.popup_content;
                        this.popup_credits = state.credits;
                        this.popup_state = state.name;
                        break;
                    case 'mouseleave': 
                        this.popup_content = '';
                        this.popup_credits = [];
                        this.popup_state = '';
                        break;
                }
                
            },
            onStateSelect(e) {
                this.selectState(e.target.value);
            },
            onMouseMove(e) {
                let position = getMousePosition(e);

                let popup_el = this.$refs['popup'];
                if(popup_el) {
                    popup_el.style.left = `${position.x}px`;
                    popup_el.style.top = `${position.y}px`;
                }
            }
        }
    }

    const getMousePosition = (e) => {
        var rect = e.currentTarget.getBoundingClientRect();

        return {
            x : e.clientX - rect.left,
            y : e.clientY - rect.top  
        }

    }
</script>
