class Modal {
    constructor(el = null, options = {}) {
        this.modal_el = el; // dom element representing modal
        this.visible = false; // is the modal currently visible

        // make sure we have an id for this modal
        this.modal_el.id = this.modal_el.id || `atd-modal-${Modal.modals.length + 1}`;

        this.options = Object.assign({
            background_close: true, // allow closing by clicking on overlay
            transition_time: 300, // time (ms) for transition animation
            esc_close: true, // allow closing by esc key
            toggle_selector: false, // additional css selectors to create additional toggle buttons
            onOpen: null, // callback on open
            onClose: null // callback on close
        }, options);

        // function to handle events to listener toggles
        this.toggleModalListener = (e) => {
            console.log(`#${this.modal_el.id}`, e.target.getAttribute('href') === `#${this.modal_el.id}`)
            if(e.target.dataset.toggleModal === this.modal_el.id || e.target.matches(this.options.toggle_selector) || e.target.getAttribute('href') === `#${this.modal_el.id}`) {
                e.preventDefault();
                this.toggleModal();
                return;
            }

            // toggle for modal overlay
            if(e.target.id === this.modal_el.id && this.options.background_close) {
                e.preventDefault();
                this.toggleModal();
                return;
            }
        }

        // set up event listeners
        this.setEventListeners();

        // add instance to static modal array
        Modal.modals.push(this);
    }

    // function to open and close modal
    toggleModal() {
        this.modal_el.classList.add('transitioning'); // add class to mark transition start

        window.setTimeout(() => {
            if(this.visible) { // if its open...
                this.visible = false; // ... close it
                this.modal_el.classList.remove('active');
                document.documentElement.classList.remove('noscroll');
                if(typeof this.options.onClose === 'function') {
                    this.options.onClose.call(this); //call onClose if it exists
                }
            }
            else { // if its closed
                if(typeof this.options.onOpen === 'function') {
                    if(this.options.onOpen.call(this) === false) { // call onOpen if it exists
                        this.modal_el.classList.remove('transitioning');
                        return;
                    }
                }

                this.visible = true; // open it
                this.modal_el.classList.add('active');
                document.documentElement.classList.add('noscroll');
            }

            window.setTimeout(() => { // wait for duration of transition_time
                this.modal_el.classList.remove('transitioning'); // add class to mark transition enc
            }, this.options.transition_time);
        }, 0);
    }

    // function to show modal
    showModal() {
        this.visible = true;
        this.modal_el.classList.add('transitioning');

        window.setTimeout(() => {
            this.modal_el.classList.add('active');
            document.documentElement.classList.add('noscroll');

            if(typeof this.options.onOpen === 'function') {
                this.options.onOpen.call(this);
            }

            window.setTimeout(() => {
                this.modal_el.classList.remove('transitioning');
            }, this.options.transition_time);
        }, 0);
    }

    // function to hide modal
    hideModal() {
        this.visible = false;
        this.modal_el.classList.add('transitioning');

        window.setTimeout(() => {
            this.modal_el.classList.remove('active');
            document.documentElement.classList.remove('noscroll');

            if(typeof this.options.onClose === 'function') {
                this.options.onClose.call(this);
            }

            window.setTimeout(() => {
                this.modal_el.classList.remove('transitioning');
            }, this.options.transition_time);
        }, 0);
    }

    // add event listener on the body
    setEventListeners() {
        document.body.addEventListener('click', this.toggleModalListener);

        if(this.options.esc_close) { // add esc key listenter
            document.body.addEventListener('keyup', (e) => {
                if(e.code === 'Escape' && this.visible) {
                    this.hideModal();
                }
            });
        }
    }

    // update modal options
    updateOptions(options) {
        this.options = Object.assign({}, this.options, options);
    }

    // return modal from static array by dom node id
    static getModalById(id) {
        let match = false;

        Modal.modals.forEach((modal) => {
            if(!match) {
                match = modal.modal_el.id === id ? modal : false;
            }
        });

        return match;
    }

    // returns a modal or null is no valid element given
    static createModal(el = null, options = {}) {
        if(!el || !(el instanceof HTMLElement)) {
            return null;
        }

        return new Modal(el, options);
    }
}

// static array to hold generated modals
Object.defineProperty(Modal, 'modals', {
    get: function() { return this.hasOwnProperty('_modals') ? this._modals : void 0; },
    set: function(v) { this._modals = v; }
});

// init stati modal array
Modal.modals = [];

export default Modal;
