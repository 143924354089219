import Vue from 'vue'
import StateMapApp from './vue/StateMapApp.vue'

Vue.config.productionTip = false

if (document.querySelector('#state-map-app')) {
    new Vue({
        el: '#state-map-app',
        template: '<StateMapApp/>',
        components: { StateMapApp }
    })
}